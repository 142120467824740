<template>
  <div>

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <div v-if="item != null">
      <b-card>
        <b-row>

          <b-col md="12" class="mobile_display_flex">
              <span @click="goBack()">
                <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
              </span>
              <h4 class="card-title float-left"> {{item.title | capitalize }}</h4>
              
          </b-col>
          <!-- <b-col md="1">
            
          </b-col> -->
        </b-row> 
        <b-card-text>
          <p style="margin-left:50px" class="margin_bottom_zero_mobile">{{item.description}}</p>
        </b-card-text>
      </b-card>

      <b-card>
        <b-row>
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              <!-- <b-badge variant="danger" class="mt-0" @click="openModel()">
                <feather-icon
                  icon="EditIcon"
                  class="mr-25"
                />
                <span>Edit</span>
              </b-badge> -->
                <!-- <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  
                  @click="openTaskModal()"
                  v-if="(item.start <= current_date) && $store.getters.currentUser.role == 'administrator'"
                >
                <feather-icon
                    icon="EditIcon"
                    class="mr-25"
                  />
                  <span>Edit</span>
              </b-button> -->
              
              <EditModal v-if="(item.start <= current_date) && $store.getters.currentUser.role == 'administrator'" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            xl="6">
            
            <table class="mt-2 mt-xl-0 w-100">
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        ID 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.unique_id}}
                </td>
              </tr>
              

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Start Date
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <!-- {{item.start | date}} -->
                  {{ sitedateTime(item.start,item.site.pdf_date_format,'' )}}
                </td>
              </tr>

              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Start Time
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.startTime | timeFormat}}
                </td>
              </tr> -->

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Work Start Date
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <!-- {{item.actual_started | dateTime2}} -->
                  {{ sitedateTime2(item.actual_started,item.site.pdf_date_format,item.site.pdf_time_format )}}
                </td>
              </tr>

              <tr class="mb-1" v-if="item.planned_time_modify == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Planned Start Time
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <!-- {{item.plannedStartTime | timeFormat}} -->
                  {{ sitetime(item.plannedStartTime,item.site.pdf_time_format) }}
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Project Site
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.site.site_name}}
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Duration 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.durations_in_day}} {{item.durations_in_day == 1 ? 'Day' : 'Days'}}
                </td>
              </tr>

              <!-- <tr class="mb-1" v-if="item.group != ''">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Group
                      </h6>
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span > {{item.group | groupArray}}</span>
                  
                </td>
              </tr> -->

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Status
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <b-badge
                    pill
                    :variant="variantColor(item.task_status)"
                    class="text-capitalize"
                  >
                    {{ item.task_status | capitalize}}
                  </b-badge>
                </td>
              </tr>

            </table>

          </b-col>

          <b-col
            cols="12"
            xl="6">
            
            <table class="mt-2 mt-xl-0 w-100">

              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Supervisor
                      </h6>
                     <small>Monthly Sales</small>
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span v-if="item.supervisor != null">{{item.supervisor.full_name | capitalize}}</span>
                  <span v-if="item.supervisor == null">N/A</span>
                </td>
              </tr> -->
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Completed By
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span v-if="item.completed_by != null">{{item.completed_by.full_name | capitalize}}</span>
                  <span v-if="item.completed_by == null">N/A</span>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        End Date
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <!-- {{item.end | date}} -->
                  {{ sitedateTime(item.end,item.site.pdf_date_format,'' )}}
                </td>
              </tr>
              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        End Time
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.endTime | timeFormat}}
                </td>
              </tr> -->

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Work End Date
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <!-- {{item.actual_end |dateTime2}} -->
                  {{ sitedateTime2(item.actual_end,item.site.pdf_date_format,item.site.pdf_time_format )}}
                </td>
              </tr>

              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Shift
                      </h6>
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{item.shift}}
                </td>
              </tr> -->

              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Completed in Min
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  {{'N/A'}}
                </td>
              </tr> -->

              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Task Type
                      </h6>
                    </div>
                  </div>
                </th> -->
                <!-- <td class="pb-50">
                  <span v-if="item.periodic == null "> {{item.type}} </span>
                  <span v-if="item.periodic != null "> Schedule </span>
                </td> 
              </tr> -->

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Locations
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span v-for="(loc, ind) in item.locations" :key="ind">{{loc.name }}</span>
                </td>
              </tr>
              <!-- <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Training Content
                      </h6>
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span v-if="item.training == null"> {{'N/A'}}</span>
                  <span v-if="item.training != null"> <a :href="item.training.url" target="_blank" >{{item.training.name | capitalize}} </a></span>
                </td>
              </tr> -->

              <tr class="mb-1" v-if="item.task_status == 'closed'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        Closed By
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span > {{item.closed_by ? item.closed_by.full_name : 'N/A'}}</span>
                  
                </td>
              </tr>

              <!-- <tr class="mb-1" v-if="item.nfc_code != ''">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-0">
                        NFC Scan Required
                      </h6>
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <span > Yes </span>
                  
                </td>
              </tr> -->

              
              
            </table>

          </b-col>
        </b-row>

        <hr v-if="item.start <= current_date">
        <div v-if="item.site.no_photo_reporting == 'no' && item.start <= current_date">
          <b-row>
                
            <b-col
            cols="4"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Before Image
              </h4>
              
            </b-col>

            <b-col
            cols="4"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                After Image
              </h4>
              
            </b-col>

            <b-col md="4" >
              <b-button
                  variant="warning"
                  class="mt-2 ml-50 mr-0 float-right"
                  @click="addImages"
                  v-if="item.task_status != 'failed' && item.task_status != 'closed'"
                >
                <feather-icon icon="PlusCircleIcon" />
                <span class="align-middle ml-50">Add More</span>
              </b-button>
            </b-col>

          </b-row>

          
          <b-row v-for="(image,index) in item.images" :key="index" class="mb-2">

            <b-col md="4">
              <b-row >
                <b-col md="12">
                  <b-media class="mb-2 mt-2" @click="$refs['biInput' +index ][0].click()">
                    <template #aside>
                      <b-avatar
                        :ref="'bI' + index"
                        :src="image.beforeImage.image != null ? image.beforeImage.image : doc_icon"
                        variant="light-warning"
                        size="200px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <input
                        :ref="'biInput' + index"
                        type="file"
                        class="d-none"
                        @input="biInput($event,index)"
                      >
                    </div>
                  </b-media>   
                </b-col>
                <b-col md="12">

                  <b-form-select v-model="image.beforeImageType" @change="updateImageType(index,'before')">

                      <b-form-select-option value="No Comment">No Comment</b-form-select-option>
                      <b-form-select-option value="N/A">N/A</b-form-select-option>
                      <b-form-select-option value="Restricted">Restricted</b-form-select-option>
                      <b-form-select-option value="Others">Others</b-form-select-option>

                  </b-form-select>

                </b-col>

                <b-col md="12" v-if="image.beforeImageType == 'Others'">

                  <b-form-textarea
                    placeholder="upto 500 characters"
                    rows="3"
                    v-model="image.beforeImageComment"
                    class="mt-2"
                    @change="updateComment(index,'before')"
                  />

                </b-col>

              </b-row>     
            </b-col>

            <b-col md="4">
              <b-row>
                <b-col md="12">

                  <b-media class="mb-2 mt-2" @click="$refs['aiInput' +index ][0].click()" >
                    <template #aside>
                      <b-avatar
                        :ref="'aI' + index"
                        :src="image.afterImage.image != null ? image.afterImage.image : doc_icon"
                        variant="light-secondary"
                        size="200px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <input
                        :ref="'aiInput' + index"
                        type="file"
                        class="d-none"
                        @input="aiInput($event,index)"
                      >
                    </div>

                    
                  </b-media>
                </b-col>
                <b-col md="12">

                  <b-form-select v-model="image.afterImageType" @change="updateImageType(index,'after')">

                      <b-form-select-option value="No Comment">No Comment</b-form-select-option>
                      <b-form-select-option value="N/A">N/A</b-form-select-option>
                      <b-form-select-option value="Restricted">Restricted</b-form-select-option>
                      <b-form-select-option value="Others">Others</b-form-select-option>

                  </b-form-select>

                </b-col>

                <b-col md="12" v-if="image.afterImageType == 'Others'">

                  <b-form-textarea
                    placeholder="upto 500 characters"
                    rows="3"
                    v-model="image.afterImageComment"
                    class="mt-2"
                    @change="updateComment(index,'after')"
                  />

                </b-col>

              </b-row>        
            </b-col>


            <b-col md="4">

              <b-button
                  variant="danger"
                  class="mt-2 ml-50 mr-0 float-right"
                  @click="removeBI(index)"
                  v-if="item.task_status != 'failed' && item.task_status != 'closed'"
                >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Remove</span>
              </b-button>

            </b-col>


          </b-row>

          <hr>
        </div>

        <div v-if="item.site.no_photo_reporting == 'yes' && item.start <= current_date">
          <b-row>
                
            <b-col
            cols="8"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Task Comment
              </h4>
              
            </b-col>

            <b-col md="4" >
              <b-button
                  variant="warning"
                  class="mt-2 ml-50 mr-0 float-right"
                  @click="addComment"
                  v-if="item.task_status != 'failed' && item.task_status != 'closed'"
                >
                <feather-icon icon="PlusCircleIcon" />
                <span class="align-middle ml-50">Add More</span>
              </b-button>
            </b-col>

          </b-row>

          <b-row v-for="(c,index) in item.comments" :key="index" class="mb-2">

            <b-col md="8">
                <b-form-textarea
                  placeholder="upto 500 characters"
                  rows="2"
                  v-model="c.comment"
                  class="mt-2"
                  @change="updateTaskComment(index)"
                />
            </b-col>


            <b-col md="4">

              <b-button
                  variant="danger"
                  class="mt-2 ml-50 mr-0 float-right"
                  @click="removeComment(index)"
                  v-if="item.task_status != 'failed' && item.task_status != 'closed'"
                >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Remove</span>
              </b-button>

            </b-col>


          </b-row>

          <hr>
        </div>

        <!-- <b-row  class="mobile_signature_img" v-if="item.start <= current_date && $store.getters.currentUser.role == 'administrator'">

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
          > 

            
              
                <b-col
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    Signature (Completed By)
                  </h4>
                  
                </b-col>
            

            
                <b-col
                  class="mb-2 mt-0 mobile_pdng_lft_rgt_zero margin_bottom_zero_mobile"
                > 
              
                    <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('supervisor')" v-if="item.supervisorSignature == null">
                      <template #aside>
                        <b-avatar
                          ref="supervisorS"
                          :src="supervisorSign.image != null ? supervisorSign.image : doc_icon"
                          variant="light-info"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          ref="supervisorSInput"
                          type="file"
                          class="d-none"
                          @input="supervisorImageUpload"
                        >
                      </div>

                      
                    </b-media>

                    

                    <img :src="supervisorSign.image" width="240px" height="200px" v-if="supervisorSign.image != null" style="background: #ffffff; border-radius: 4px" class="mt-1">

                    <center class="width_240_px">
                      <b-button
                          variant="danger"
                          class="mt-1 ml-0  mr-5 danger_btn_desktop_mrgn_right_zero"
                          v-if="item.task_status != 'closed' && item.supervisorSignature != null"
                          @click="removeSign('supervisor')"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                  </center>
                  
                  </b-col>
            
          </b-col>

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
          > 
            
              
                <b-col
                
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    Signature (Validated By)
                  </h4>
                  
                </b-col>
            

            
                <b-col
                 
                  class="mobile_pdng_lft_rgt_zero"
                > 
              
                    <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('client')" v-if="item.clientSignature == null">
                      <template #aside>
                        <b-avatar
                          ref="clientS"
                          :src="clientSign.image != null ? clientSign.image : doc_icon"
                          variant="light-danger"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          ref="clientSInput"
                          type="file"
                          class="d-none"
                          @input="clientImageUpload"
                        >
                      </div>

                      
                    </b-media>

                    
                    <img :src="clientSign.image" width="240px" height="200px" v-if="item.clientSignature != null" style="background: #ffffff; border-radius: 4px" class="mt-1">
                    <center class="width_240_px">
                    <b-button
                        variant="danger"
                        class="mt-1 ml-0 mr-3 danger_btn_desktop_mrgn_right_zero"
                        v-if="item.task_status != 'closed' && item.clientSignature != null"
                        @click="removeSign('client')"
                      >
                      <feather-icon icon="XIcon" />
                      
                    </b-button>
                  </center>
                  </b-col>
            
          </b-col>

          
        </b-row> -->

        <b-row  class="mobile_signature_img" v-if="item.start <= current_date">

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
            v-show="item.task_status == 'in_progress'"
          > 

            
              
                <b-col
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    Signature (Completed By)
                  </h4>
                  
                </b-col>
            

            
                <b-col
                  class="mb-2 mt-0 mobile_pdng_lft_rgt_zero margin_bottom_zero_mobile"
                > 
              
                    <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('supervisor')" v-if="item.supervisorSignature == null">
                      <template #aside>
                        <b-avatar
                          ref="supervisorS"
                          :src="supervisorSign.image != null ? supervisorSign.image : doc_icon"
                          variant="light-info"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          ref="supervisorSInput"
                          type="file"
                          class="d-none"
                          @input="supervisorImageUpload"
                        >
                      </div>

                      
                    </b-media>

                    

                    <img :src="supervisorSign.image" width="240px" height="200px" v-if="supervisorSign.image != null" style="background: #ffffff; border-radius: 4px" class="mt-1">

                    <center class="width_240_px">
                      <b-button
                          variant="danger"
                          class="mt-1 ml-0  mr-5 danger_btn_desktop_mrgn_right_zero"
                          v-if="item.task_status != 'closed' && item.supervisorSignature != null"
                          @click="removeSign('supervisor')"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                  </center>
                  
                  </b-col>
            
          </b-col>

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
            v-show="item.task_status == 'validate'"
          > 
            
              
                <b-col
                
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    Signature (Validated By)
                  </h4>
                  
                </b-col>
            

            
                <b-col
                 
                  class="mobile_pdng_lft_rgt_zero"
                > 
              
                    <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('client')" v-if="item.clientSignature == null">
                      <template #aside>
                        <b-avatar
                          ref="clientS"
                          :src="clientSign.image != null ? clientSign.image : doc_icon"
                          variant="light-danger"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          ref="clientSInput"
                          type="file"
                          class="d-none"
                          @input="clientImageUpload"
                        >
                      </div>

                      
                    </b-media>

                    
                    <img :src="clientSign.image" width="240px" height="200px" v-if="item.clientSignature != null" style="background: #ffffff; border-radius: 4px" class="mt-1">
                    <center class="width_240_px">
                    <b-button
                        variant="danger"
                        class="mt-1 ml-0 mr-3 danger_btn_desktop_mrgn_right_zero"
                        v-if="item.task_status != 'closed' && item.clientSignature != null"
                        @click="removeSign('client')"
                      >
                      <feather-icon icon="XIcon" />
                      
                    </b-button>
                  </center>
                  </b-col>
            
          </b-col>

          
        </b-row>





        <b-row class="mt-1" v-if="item.task_status == 'in_progress'">
          
          <b-col>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class = "mr-1"
              variant="danger"
              @click="updateTaskStatus('open',item)"
            >
              Abort Task
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class = "mr-1"
              variant="primary"
              @click="updateTaskStatus('validate',item)"
            >
              Complete
            </b-button>

          </b-col>

        </b-row>

        <b-row class="mt-1" v-if="item.task_status == 'validate'">
          
          <b-col>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class = "mr-1"
              variant="danger"
              @click="updateTaskStatus('in_progress',item)"
            >
              Re-do Task
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class = "mr-1"
              variant="primary"
              @click="updateTaskStatus('closed',item)"
            >
              Close Task
            </b-button>

          </b-col>

        </b-row> 

        

        
      </b-card>


      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>


    </div>

    

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormDatepicker,BFormCheckbox,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import EditModal from './EditModal';
import Bus from "../../../event-bus";

var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormDatepicker,flatPickr,BFormCheckbox,BBreadcrumb, EditModal
  },
  
  data() {
    return {
      allCrews:[],
      supervisors:[],
    //   trainings:[],
      clearButton:true,
      item:null,
      showDismissibleAlert:false,
      popshowDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      clientSign : {
        image:null,
        name:'',
      },
      supervisorSign : {
        image:null,
        name:'',
      },
      shifts:[],

      form : {
        task_status : '',
        shift:'',
        start:null,
        end:null,
        crew:null,
        // training:null,
        description:'',
        periodic:null,
        no_photo_reporting:'no',
        startTime:'',
        endTime:'',
        start_modify:'no',
        end_modify:'no',
        reason_for_yes:'',
        reason_for_no:'',
        plannedStartTime:'',
        completed_by:'',
      },
      openedSignModel:null,
      allUsers: [],
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
      current_date: moment(new Date()).tz('Asia/Singapore').format('YYYY-MM-DD')
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    
    variantColor(status){
      if (status == 'open') {
        return 'primary';
      }else if(status == 'in_progress'){
        return 'warning'
      }else if(status == 'validate'){
        return 'info'
      }else if(status == 'failed'){
        return 'danger'
      }else{
        return 'success';
      }
    },

    taskDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id,
           },
           api: '/api/feedback-request-status-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.item = this.$store.getters.getResults.data;

                if (this.item.clientSignature == null) {
                  this.clientSign = {
                    image:null,
                    name:'',
                  }
                }else{

                  this.clientSign = {
                    image:this.item.clientSignature+'?'+new Date(),
                    name:'',
                  }

                }

                if (this.item.supervisorSignature == null) {
                  this.supervisorSign = {
                    image:null,
                    name:'',
                  }
                }else{

                  this.supervisorSign = {
                    image:this.item.supervisorSignature+'?'+new Date(),
                    name:'',
                  }

                }


                if (this.item.images.length == 0) {
                  
                  var obj = {
                    
                    beforeImage : {
                      image:null,
                      name:'',
                    },
                    afterImage : {
                      image:null,
                      name:''
                    },
                    beforeImageType:'No Comment',
                    afterImageType:'No Comment',
                    beforeImageComment:null,
                    afterImageComment:null,
                  }

                  this.item.images.push(obj);

                }else{

                  this.item.images.forEach(function(item){
                    item.beforeImage = {
                      image: item.beforeImage,
                      name:''
                    }

                    item.afterImage = {
                      image: item.afterImage,
                      name:''
                    }

                  })


                }
                this.form.no_photo_reporting = this.item.site.no_photo_reporting;

                if (this.item.comments.length == 0) {
                  this.item.comments.push({comment:''});
                }


                this.allSiteCrews();


                this.siteUser(this.item.site._id);

                var shifts = [];

                this.item.site.shifts.forEach(item => {
                  shifts.push(item.shift);
                })

                this.shifts = shifts;
                return this.item;
            }
        });
    },
    addImages(){
      var obj = {
                    
        beforeImage : {
          image:null,
          name:'',
        },
        afterImage : {
          image:null,
          name:''
        },
        beforeImageType:'No Comment',
        afterImageType:'No Comment',
        beforeImageComment:null,
        afterImageComment:null,
      }

      this.item.images.push(obj);
    },
    removeBI(index){

      if (typeof this.item.images[index]._id == 'undefined') {
        
        this.item.images.splice(index,1);

        if (this.item.images.length == 0) {
            var obj = {
                    
              beforeImage : {
                image:null,
                name:'',
              },
              afterImage : {
                image:null,
                name:''
              },
              beforeImageType:'No Comment',
              afterImageType:'No Comment',
              beforeImageComment:null,
              afterImageComment:null,
            }

            this.item.images.push(obj);
        }

      }else{

        var msg = 'Are you sure want to permanent remove these Images?';
        var message='Images Removed Successfully.';

        Swal.fire({
                  title: 'Please Confirm',
                  text: msg,
                  icon: 'warning',
                  position: 'center',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No'
                })
              .then(result => {
                if (result.value) {

                  var data = {
                    id : this.item.images[index]._id,
                  }

                  this.removeDBImage(data);
                  this.item.images.splice(index,1);  
                  
                  if (this.item.images.length == 0) {

                      var obj = {
                    
                        beforeImage : {
                          image:null,
                          name:'',
                        },
                        afterImage : {
                          image:null,
                          name:''
                        },
                        beforeImageType:'No Comment',
                        afterImageType:'No Comment',
                        beforeImageComment:null,
                        afterImageComment:null,
                      }

                      this.item.images.push(obj);
                  }

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  }).then((result) => {
                      
                  });
                }
              });

      }
    },
    biInput(event,index){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['biInput'+index][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['biInput'+index][0].value=null;
              

          } else {
              reader.onload = (e) => {

                /*this.item.beforeImage[index].image = e.target.result;
                this.item.beforeImage[index].name = image.name;*/

                /*
                */
                if (typeof this.item.images[index]._id != 'undefined') {
                  //update

                  var imageData = {
                      id: this.item.images[index]._id,
                      image : e.target.result,
                      name : image.name,
                      type: 'before'
                  }

                  this.updateTaskImage(imageData)

                }else{
                  //create

                  var imageData = {
                      image : e.target.result,
                      name : image.name,
                      type: 'before'
                  }

                  this.updateTaskImage(imageData)
                }


            }
          }
      }
      

    },

    aiInput(event,index){
      //console.log(event,index);

      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['aiInput'+index][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['aiInput'+index][0].value=null;
              

          } else {
              reader.onload = (e) => {

                if (typeof this.item.images[index]._id != 'undefined') {
                  //update

                  var imageData = {
                      id: this.item.images[index]._id,
                      image : e.target.result,
                      name : image.name,
                      type: 'after'
                  }

                  this.updateTaskImage(imageData)

                }else{
                  //create

                  var imageData = {
                      image : e.target.result,
                      name : image.name,
                      type: 'after'
                  }

                  this.updateTaskImage(imageData)
                }
                

            }
          }
      }
      

    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                /*this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;*/
                var imageData = {
                  image : e.target.result,
                  name : image.name,
                  type: 'supervisor'
                }
                this.updateTaskSignature(imageData)

            }
          }
      }
      

    },
    clientImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.clientSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.clientSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                /*this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;*/
                var imageData = {
                  image : e.target.result,
                  name : image.name,
                  type: 'client'
                }
                this.updateTaskSignature(imageData)

            }
          }
      }
      

    },

    updateTaskImage(data){
      return this.$store.dispatch(POST_API, {
           data:{
             items:data,
             id:this.$route.params.id,
           },
           api: '/api/add-feedback-request-images'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                
                this.taskDetail();
                
            }
        });
    },

    updateImageType(index,type){
      if(type == 'after'){
        if (this.item.images[index].afterImageType == 'Others') {
          this.item.images[index].afterImageComment = '';
        }else{
          this.item.images[index].afterImageComment = this.item.images[index].afterImageType;
        }

      }else{
        if (this.item.images[index].beforeImageType == 'Others') {
          this.item.images[index].beforeImageComment = '';
        }else{
          this.item.images[index].beforeImageComment = this.item.images[index].beforeImageType;
        }
      }

      this.updateComment(index,type);
    },
    updateComment(index,type){


      return this.$store.dispatch(POST_API, {
           data:{
             commentData:this.item.images[index],
             id:this.$route.params.id,
           },
           api: '/api/add-feedback-request-images-comment'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                
                this.error_message = this.$store.getters.getErrors;
                
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500
                })

                this.taskDetail();

            } else {
                this.showDismissibleAlert = false;
                
                this.taskDetail();
                
            }
        });


    },

    removeDBImage(data){
        return this.$store.dispatch(POST_API, {
           data:{
             items:data,
             id:this.$route.params.id,
           },
           api: '/api/remove-feedback-request-images'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                
                //this.taskDetail();
                
            }
        });
    },
    removeDBComment(data){
        return this.$store.dispatch(POST_API, {
           data:{
             items:data,
             id:this.$route.params.id,
           },
           api: '/api/remove-feedback-request-comment'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                
                //this.taskDetail();
                
            }
        });
    },
    removeSign(sign){


      var msg = 'Are you sure want to permanent remove this Signature?';
      var message='Signature Removed Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
      .then(result => {
        if (result.value) {
            return this.$store.dispatch(POST_API, {
               data:{
                 signature:sign,
                 id:this.$route.params.id,
               },
               api: '/api/remove-feedback-request-signature'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                } else {
                    this.showDismissibleAlert = false;
                    
                    this.taskDetail();
                    
                }
            });

        }
      });
    },
    openModel(){
      this.form.reason_for_yes = '';
      this.form.reason_for_no = '';

      this.popshowDismissibleAlert = false;

      this.form.task_status = this.item.task_status;
      this.form.shift = this.item.shift;
      this.form.start = this.item.start;
      this.form.end = this.item.end;
      this.form.crew = this.item.crew ? this.item.crew._id : '';
    //   this.form.training = this.item.training ? this.item.training._id : '';

      this.form.completed_by = this.item.completed_by ? this.item.completed_by._id : '';

      this.form.description = this.item.description;
      this.form.periodic = this.item.periodic;
      this.form.startTime = this.item.startTime;
      this.form.endTime = this.item.endTime;
      this.form.start_modify = this.item.start_modify;
      this.form.end_modify = this.item.end_modify;
      this.form.plannedStartTime = this.item.plannedStartTime;
      /* if(this.item.comments.length > 0){
        if(this.item.comments[0].hasOwnProperty('reason')){
          this.form.reason_for_yes = this.item.comments[0].reason;
        }
      }

      if(this.item.images.length > 0){
        if(this.item.images[0].hasOwnProperty('reason')){
          this.form.reason_for_no = this.item.images[0].reason;
        }
      } */

      this.$refs['task_detail'].show();
    },
    updateTask(bvModalEvt){

      bvModalEvt.preventDefault()
      
      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form,
             id:this.$route.params.id,
           },
           api: '/api/update-feedback-request-status-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Task Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.taskDetail();
                    this.$refs['task_detail'].hide();
                });

                this.reason_for_yes = '';
                this.reason_for_no = '';
            }
        });
    },
    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    // trainingData(){
    //   return this.$store.dispatch(GET_API, {
    //        data:{
             
    //        },
    //        api: '/api/all-trainings'
    //     })
    //     .then(() => {
    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.showDismissibleAlert = true;
    //             //window.scrollTo(0,0);
    //         } else {
    //             this.showDismissibleAlert = false;
    //             var data  = this.$store.getters.getResults.data;
                
    //             this.trainings = data;
    //             return this.trainings;
    //         }
    //     });
    // },
    allSiteCrews(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.item.site
           },
           api: '/api/all-crew'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                
                this.allCrews = data;

                
                return this.allCrews;
            }
        });
    },

    goBack(){
      
  		var filter = {
  			keyword       : this.$route.params.keyword,
  			sortBy        : this.$route.params.sortBy,
  			sortDirection : this.$route.params.sortDirection,
  			site          : this.$route.params.site,
  			location      : this.$route.params.location,
        crew          : this.$route.params.crew,
        status        : this.$route.params.status,
        tab_status    : this.$route.params.tab_status,
        start         : this.$route.params.start,
        end           : this.$route.params.end,
        assign_status : this.$route.params.assign_status
  		};
  		this.$router.push({ name: 'feedback-request', params: filter });
    },
    
    addComment(){
      	this.item.comments.push({comment:''});
    },
    removeComment(index){

      if (typeof this.item.comments[index]._id == 'undefined') {
        
        this.item.comments.splice(index,1);

        if (this.item.comments.length == 0) {
            

            this.item.comments.push({comment:''});
        }

      }else{

        var msg = 'Are you sure want to permanent remove this comment?';
        var message='Comment Removed Successfully.';

        Swal.fire({
                  title: 'Please Confirm',
                  text: msg,
                  icon: 'warning',
                  position: 'center',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No'
                })
              .then(result => {
                if (result.value) {

                  var data = {
                    id : this.item.comments[index]._id,
                  }

                  this.removeDBComment(data);
                  this.item.comments.splice(index,1);  
                  
                  if (this.item.comments.length == 0) {

                      this.item.comments.push({comment:''});
                  }

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  }).then((result) => {
                      
                  });
                }
              });

      }
    },
    updateTaskComment(index){
     
      return this.$store.dispatch(POST_API, {
           data:{
             commentData:this.item.comments[index],
             id:this.$route.params.id,
           },
           api: '/api/add-feedback-request-comment'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                
                this.error_message = this.$store.getters.getErrors;
                
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500
                })

                this.taskDetail();

            } else {
                this.showDismissibleAlert = false;
                
                this.taskDetail();
                
            }
        });
    },
    siteUser(site){
      
      return this.$store.dispatch(POST_API, {
           data:{
             site:site,
           },
           api: '/api/all-organization-users'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.allUsers = data;

                return this.allUsers;
            }
        });
    },
    view(id){
      var keyword       = this.$route.params.keyword ? this.$route.params.keyword.replace(/_/g,' '):"null";
      var sortBy        = this.$route.params.sortBy ? this.$route.params.sortBy:"null";
      var sortDirection = this.isSortDirDesc == false ? 'false':'true';      
      var site          = this.$route.params.site ? this.$route.params.site : "null";
      var location      = this.$route.params.location ? this.$route.params.location : "null";
			var crew          = this.$route.params.crew ? this.$route.params.crew : "null";;
			var status        = this.$route.params.status ? this.$route.params.status : "null";;
			var tab_status    = this.$route.params.tab_status ? this.$route.params.tab_status : "null";;
			var start         = this.$route.params.start ? this.$route.params.start : "null";;
			var end           = this.$route.params.end ? this.$route.params.end : "null";;

      var filter = {
        id            : id,
        keyword       : keyword,
        sortBy        : sortBy,
        sortDirection : sortDirection,
        site          : site,
				location      : location,
				crew          : crew,
				status        : status,
				tab_status    : tab_status,
				start         : start,
				end           : end
      };
      this.$router.push({ name: 'edit-feedback-request-status', params: filter });
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.having_default = true;
                }
            }
        });

    },
    openSignature(type){

      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
      
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    saveDefault(){
      
      if (this.openedSignModel == 'supervisor') {
                    
        var imageData = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        
        this.updateTaskSignature(imageData)
      }else{

        var imageData = {
          image : this.tempSign.image,
          name : 'signature.png',
          type: 'client',
          default:'yes'
        }
        
        this.updateTaskSignature(imageData)

      }

      this.$refs['signatureModel'].hide();
    },
    updateTaskSignature(data){
      return this.$store.dispatch(POST_API, {
           data:{
             items:data,
             id:this.$route.params.id,
           },
           api: '/api/add-feedback-request-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                
                this.taskDetail();
                
            }
        });
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'client') {

         this.$refs['clientSInput'].click();
      }

      this.$refs['signatureModel'].hide();
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor') {
          var imageData = {
            image : data,
            name : 'siganture.png',
            type: 'supervisor'
          }
          
          this.updateTaskSignature(imageData)
        }else{

          var imageData = {
            image : data,
            name : 'signature.png',
            type: 'client'
          }
          
          this.updateTaskSignature(imageData)

        }

        this.$refs['signatureModel'].hide();
      }
    },
    breadCrumb(){
      var filter = {
        keyword       : this.$route.params.keyword,
        sortBy        : this.$route.params.sortBy,
        sortDirection : this.$route.params.sortDirection,
        site          : this.$route.params.site,
        location      : this.$route.params.location,
        crew          : this.$route.params.crew,
        status        : this.$route.params.status,
        tab_status    : this.$route.params.tab_status,
        start         : this.$route.params.start,
        end           : this.$route.params.end
      };

      var item = [{
				to:{name:'client-dashboard'},
				text: 'Dashboard',
			},{
				to:null,
				text: 'Feedback System',
			},{
				to:null,
				text: 'Reports',
			},{
				to:{ name: 'feedback-request', params: filter },
				text:'Feedback Request',
			},{
				to:null,
				text: 'Feedback Request Detail',
				active:true
			}];

      return item;
    },
    updateSignature(){
      if ((this.item.task_status == 'in_progress') && (this.item.supervisorSignature == null) 
        && (this.item.start <= this.current_date) ) {
          //console.log('1');
          var imageData = {
            image : this.tempSign.image,
            name : 'signature.png',
            type :   'supervisor',
            default:'yes'
          }
          
          this.updateTaskSignature(imageData)
        }else if ((this.item.task_status == 'validate') && (this.item.clientSignature == null) 
          && (this.item.start <= this.current_date)) {
          
          if (this.tempSign && this.tempSign.image != null) {
              
              var imageData = {
                image : this.tempSign.image,
                name : 'signature.png',
                type :   'client',
                default:'yes'
              }
              
              this.updateTaskSignature(imageData)
          }
          
        }
    },
    
    updateTaskStatus(status,task){

        var txt = "";
        if (status == 'open') {
          txt = 'abort';
        }else if (status == 'validate') {
          txt = 'complete'
        }else {
          txt = status;
        }
        var msg = 'Are you sure want to '+ txt +' this task?';
        
        Swal.fire({
            title: 'Please Confirm',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        .then(result => {
            if (result.value) {

                return this.$store.dispatch(POST_API, {
                    data:{
                        status:status,
                        id:task._id,
                        no_photo_reporting:task.site.no_photo_reporting
                    },
                    api: '/api/app-feedback-request-update'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        Swal.fire({
                            icon: 'warning',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        })                
                    } else {
                        this.popshowDismissibleAlert = false;
                        Swal.fire({
                            icon: 'success',
                            title: 'Feedback Request Updated Successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            if (status == 'validate' && this.$store.getters.currentUser.role == 'supervisor') {

                              this.$route.params.tab_status = 'closed';

                            }else{

                              this.$route.params.tab_status = status;
                            }
                            this.goBack();
                        });
                    }
                });
            }
        })
        .catch(err => {})
    },
    
  },
  mounted(){
    this.useDefault();
    this.taskDetail().then(() => {
      this.updateSignature();
    });
    Bus.$on('update-data', (status) => {
      /*this.taskDetail().then(() => {
        this.updateSignature();
      });*/

      this.$route.params.tab_status = status;
      this.goBack();

    })
    // this.trainingData();


    
  }
  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
